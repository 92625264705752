import styled from "styled-components";
import { TFunction } from "i18next";

export const Separator = styled.hr`
  border: none;
  background: #D8D8DC;
  height: 1px;
  margin: 16px -16px;
`;

export const CenterRow = styled.div<{marginLeft?: string}>`
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  @media (min-width: ${({theme}) => theme.breakpoints[0]}) {
    ${({ marginLeft }) => marginLeft === undefined ? "" : `margin-left: ${marginLeft};`}
  }
`

export const getTableHeaders = (t: TFunction) => [
    {
      content: <CenterRow marginLeft='24px'>{ t("Table.Header.Status") }</CenterRow>,
    },
    {
      content: t("Table.Header.Date"),
    },
    {
      content: t("Table.Header.Device"),
    },
  ]
