import React, { FC } from "react";
import { ResponsiveTable } from "@ig-phoenix/responsive-table";
import { useTranslation } from "react-i18next";
import { Paragraph } from "@ig-phoenix/paragraph";
import { TFunction } from "i18next";
import { Loading } from "@ig-phoenix/icon";
import { Activity, ENV } from "../types";
import { getTableHeaders } from "./utils";
import { DeviceField, formatDate, StatusField } from "./TableFields";
import { useGetActivities } from "../api";

interface Props {
  env: ENV;
}

const rowFormatter = (activities: Activity[], t: TFunction, language: string) => activities.map(({ status, device, createdDate, id }) => ({
    columns: [
      { content: StatusField({ status, t }) },
      { content: formatDate(createdDate, language) },
      { content: DeviceField({ device, t }) },
    ],
    id,
  }))
export const ActivityList: FC<Props> = ({env}) => {
  const { t, i18n } = useTranslation();
  const {data, isError, isLoading} = useGetActivities({ env, pageNumber: 0, itemsPerPage: 25 });

  if (isLoading) {
    return (<><Paragraph>{t("Utility.Loading")}</Paragraph><Loading/></>)
  }

  if (isError) {
    return (<Paragraph variant='negativeAmount'>{t("Utility.Error")}</Paragraph>)
  }

  return (
    <ResponsiveTable rows={ rowFormatter(data.content, t, i18n.language) } headers={ getTableHeaders(t) }/>
  )
};

export default ActivityList;
