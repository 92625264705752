export type GALoginActivityEvent = {
  event: "virtual_page_view";
  page_section: "Login activity";
  virtual_report_path: "myig/security/login-activity";
  page: string;
  form_category?: string;
  form_name?: string;
  page_type?: string;
  page_category: "Security login activity";
  registration_type?: string;
};

const pushEvent = (event: GALoginActivityEvent) =>
  window.dataLayer?.push(event);

export const trackVirtualPageView = () => {
  pushEvent({
    event: "virtual_page_view",
    page: window.location.href,
    page_category: "Security login activity",
    page_section: "Login activity",
    virtual_report_path: "myig/security/login-activity",
  });
};
