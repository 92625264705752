import { useQuery } from "@tanstack/react-query";
import { get } from "js-cookie";
import axios from "axios";
import { type Activity, type ENV } from "./types";

export interface AuthHeaders extends Record<string, string> {
  CST: string;
  "X-SECURITY-TOKEN": string;
}

export const getAuthHeaders = (): AuthHeaders => ({
  CST: get("CST") || "",
  "X-SECURITY-TOKEN": get("X-SECURITY-TOKEN") || "",
});

const getDomainUrl = (env: ENV): string => {
  switch (env) {
    case "LIVE":
      return "https://www.ig.com";
    case "DEMO":
      return "https://demo.ig.com";
    case "UAT":
      return "https://web.ig.com";
    case "TEST":
    default:
      return "https://net.ig.com";
  }
}

type GetStatusProps = {
  pageNumber: number;
  itemsPerPage: number;
  env: ENV;
}

export type ActivitiesResponse = {
  "content": Activity[],
  "metadata": {
    "totalPages": number,
    "totalElements": number,
    "pageNo": number,
    "pageSize": number,
    "last": boolean
  }
}

export const useGetActivities = ({ pageNumber, env, itemsPerPage }: GetStatusProps) => useQuery(["getActivities", pageNumber, itemsPerPage], () => axios.get<ActivitiesResponse>(
  `${ getDomainUrl(env) }/clientsecurity/v1/clients/activities?page=${pageNumber}&size=${itemsPerPage}`,
  { headers: getAuthHeaders() }
).then((response) => response.data));
