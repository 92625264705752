import { ReactNode } from "react";
import { TFunction } from "i18next";
import { Tooltip } from "@ig-phoenix/tooltip";
import { Informative } from "@ig-phoenix/icon";
import { Paragraph } from "@ig-phoenix/paragraph";
import { Desktop, Phone } from "./Icons";
import { ActivityDevice, type ActivityStatus } from "../types";
import { CenterRow } from "./utils";

interface StatusFieldProps {
  status: ActivityStatus;
  t: TFunction;
}

const TooltipContent = (t: TFunction) => (<Tooltip.Body>{t("Tooltip.p1")}<br/><br/>{t("Tooltip.p2")}</Tooltip.Body>)

export const StatusField = ({ status, t }: StatusFieldProps): ReactNode => {

  if (status === "SUCCESSFUL")
    return <CenterRow marginLeft='24px'><Paragraph>{ t(`Table.Fields.status.${ status }`) }</Paragraph></CenterRow>

  return (
    <CenterRow marginLeft='24px'>
      <Paragraph>
        {t(`Table.Fields.status.${status}`)}
      </Paragraph>
      <Tooltip tooltipRender={() => TooltipContent(t)}>
        <div style={{ display: "flex" }}>
          <Informative variant='secondary'/>
        </div>
      </Tooltip>
    </CenterRow>
  )
}

export const formatDate = (dateString: string, language: string): string => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat(language.replace("_", "-"), {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  }).format(date)
}

interface DeviceFieldProps {
  device?: ActivityDevice;
  t: TFunction;
}

const icons = {
  computer: <Desktop/>,
  phone: <Phone/>
}

export const DeviceField = ({ device, t }: DeviceFieldProps): ReactNode => {
  if (device === undefined) {
    return t("Utility.Unknown");
  }
  return (
  <CenterRow>
    {
      icons[device.type]
    }
    {
      device.displayName
    }
  </CenterRow>
)}
