import React, { FC } from "react";
import { useIsSmallScreen } from "@ig-phoenix/style-provider";
import { useTranslation } from "react-i18next";
import { Card } from "@ig-phoenix/card";
import { Header } from "@ig-phoenix/header";
import { Paragraph } from "@ig-phoenix/paragraph";
import { ActivityList } from "./components/ActivityList";
import { ENV } from "./types";
import { Separator } from "./components/utils";

export interface LoginActivityProps {
  env: ENV;
  embedded: boolean;
}

const LoginActivity: FC<LoginActivityProps> = ({ env, embedded }) => {
  const { t } = useTranslation();
  const isSmallScreen = useIsSmallScreen();

  return (
    <Card>
      {
        !embedded && <><Header data-testid='loginActivityHeader' size="medium" variant="h4" textAlign='left'>
          {t("Header.Title")}
        </Header>
          <Separator/></>
      }
      <Paragraph size="large" variant='secondary' textAlign='left' marginBottom='16px'>
        {t("Header.SubTitle")}
      </Paragraph>
      <Paragraph marginBottom={ isSmallScreen ? "0px" : "24px" } marginTop={ isSmallScreen ? "16px" : "0px" }>
        {t("Header.Description")}
      </Paragraph>
      <ActivityList env={env}/>
    </Card>
  );
};

export default LoginActivity;
