import { FC } from "react";

export const Phone: FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_14_1594" style={ { maskType: "alpha" } } maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
      <rect width="16" height="16" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_14_1594)">
      <path d="M4.66683 15.3334C4.30016 15.3334 3.98627 15.2029 3.72516 14.9417C3.46405 14.6806 3.3335 14.3667 3.3335 14.0001V2.00008C3.3335 1.63341 3.46405 1.31953 3.72516 1.05841C3.98627 0.797304 4.30016 0.666748 4.66683 0.666748H11.3335C11.7002 0.666748 12.0141 0.797304 12.2752 1.05841C12.5363 1.31953 12.6668 1.63341 12.6668 2.00008V14.0001C12.6668 14.3667 12.5363 14.6806 12.2752 14.9417C12.0141 15.2029 11.7002 15.3334 11.3335 15.3334H4.66683ZM4.66683 12.0001V14.0001H11.3335V12.0001H4.66683ZM8.00016 13.6667C8.18905 13.6667 8.34738 13.6029 8.47516 13.4751C8.60294 13.3473 8.66683 13.189 8.66683 13.0001C8.66683 12.8112 8.60294 12.6529 8.47516 12.5251C8.34738 12.3973 8.18905 12.3334 8.00016 12.3334C7.81127 12.3334 7.65294 12.3973 7.52516 12.5251C7.39738 12.6529 7.3335 12.8112 7.3335 13.0001C7.3335 13.189 7.39738 13.3473 7.52516 13.4751C7.65294 13.6029 7.81127 13.6667 8.00016 13.6667ZM4.66683 10.6667H11.3335V4.00008H4.66683V10.6667ZM4.66683 2.66675H11.3335V2.00008H4.66683V2.66675Z" fill="#5A5E5F"/>
    </g>
  </svg>
)

export const Desktop: FC = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_14_1572" style={ { maskType: "alpha" } } maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="16">
      <rect width="16.3471" height="16" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_14_1572)">
      <path d="M0.681152 14V12.6667H15.666V14H0.681152ZM2.72454 12C2.34992 12 2.02922 11.8694 1.76245 11.6083C1.49567 11.3472 1.36228 11.0333 1.36228 10.6667V3.33333C1.36228 2.96667 1.49567 2.65278 1.76245 2.39167C2.02922 2.13056 2.34992 2 2.72454 2H13.6226C13.9973 2 14.318 2.13056 14.5847 2.39167C14.8515 2.65278 14.9849 2.96667 14.9849 3.33333V10.6667C14.9849 11.0333 14.8515 11.3472 14.5847 11.6083C14.318 11.8694 13.9973 12 13.6226 12H2.72454ZM2.72454 10.6667H13.6226V3.33333H2.72454V10.6667Z" fill="#5A5E5F"/>
    </g>
  </svg>
)
