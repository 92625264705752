import { useEffect, useState } from "react";
import { StyleProvider } from "@ig-phoenix/style-provider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import initialiseI18n from "./i18n";
import LoginActivity from "./LoginActivity";
import { trackVirtualPageView } from "./google-analytics";
import { ENV } from "./types";

const queryClient = new QueryClient();

export type SPAProps = {
  env: ENV;
  language: string;
  embedded?: boolean;
};

export default function Root({ env, language, embedded = false }: SPAProps) {
  const [translationsLoaded, setTranslationsLoaded] = useState(false);

  useEffect(() => {
    trackVirtualPageView();
  }, []);

  useEffect(() => {
    initialiseI18n(language).then(() => {
      setTranslationsLoaded(true);
    });
  }, [language]);

  if (!translationsLoaded) {
    return null;
  }

  return (
    <StyleProvider withFonts>
      <QueryClientProvider client={queryClient} contextSharing>
        <LoginActivity env={env} embedded={embedded} />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </StyleProvider>
  );
}
